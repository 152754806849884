<template>
    <canvas id="cnvVisualization" ref="visualization" style="width: 100%; height: 3rem;" class="border border-light bg-dark"></canvas>    
</template>
<script>
export default {
    name: 'audio-visualization',
    data() {
        return {
            canvas: null
        }
    },
    props: {
        value: null
    },
    computed: {
        width() {
            const vm = this;
            return vm.canvas.getBoundingClientRect().width;
        },
        height() {
            const vm = this;
            return vm.canvas.getBoundingClientRect().height;
        },
        context() {
            const vm = this;
            return  vm.canvas.getContext("2d");
        },
        trackDuration() {
            const vm = this;
            if (vm.value === null) return 0;
            return vm.value.track.duration;
        }        
    },
    watch: {
        value() {
            const vm = this;
            if (vm.value === null) {
                vm.clear();
                return;
            }
            vm.refresh();
        }
    },
    mounted() {
        const vm = this;
        vm.canvas = document.getElementById('cnvVisualization');
    },
    methods: {
        refresh() {
            const vm = this;
            vm.clear();
            // vm.value.tatums.forEach(t => {
            //     vm.drawTatum(t);
            //     index++;
            //     if (index > 1) return;
            // });
            vm.drawTatum(vm.value.tatums[0]);
        },
        clear() {
            const vm = this;            
            vm.context.clearRect(0, 0, vm.width, vm.height);
        },
        drawTatum(tatum) {
            const vm = this;
            var x = parseInt(vm.width * (tatum.start / vm.trackDuration));
            var width = parseInt(vm.width * (tatum.duration / vm.trackDuration));
            var height = parseInt(vm.height * tatum.confidence);
            vm.context.fillStyle = '#ffffff';            
            vm.context.fillRect(x, height, (x+width), vm.height);         
        }
    }
}
</script>
<style scoped>
</style>