import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex);

const createNewGuid = () => {
    return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
        (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    )
};

export const store = new Vuex.Store({
    state: {
        spotifyCode: null,
        spotifyState: null,
        spotifyToken: null,
        spotifyProfile: null,
        spotifyDeviceId: null,
        volume: 80,
        currentTrack: null,  
        currentSongPos: 0,
        currentMilestones: []   
    },
    getters: {
        newGuid: () => {
            var guid = createNewGuid();
            return guid;
        }, 
        getCurrentTrack: (state) => {
            return state.currentTrack;
        },
        getCurrentPos: (state) => {
            return state.currentSongPos;
        },
        getCurrentMilestones: (state) => {
            return state.currentMilestones;
        }        
    },
    mutations: {                    
        setCurrentTrack(state, track) {
            state.currentTrack = track;
        },        
        setCurrentPos(state, pos) {
            state.currentSongPos = pos;
        },     
        setCurrentMilestones(state, milestones) {
            state.currentMilestones = milestones;
        },                      
    },
    actions: {
    },
    // Sub modules
    modules: {
    }
});