import { CosmosClient } from '@azure/cosmos';
export default class CloudStorage {

    cosmosEndpoint = 'https://app-storage.documents.azure.com:443';
    cosmosConnectionString = 'AccountEndpoint=https://app-storage.documents.azure.com:443/;AccountKey=pI53iBAkisniYoHKLFz2VWQ3cy6cj8ramPuSTaavKTczyu7clBl1qLo8BZgLZqP9KTfAaktdmZBP88ejGYAH7w==';
    cosmosDb = 'SportCompanion';
    cosmosClient = null;

    database = null;

    constructor() {
        this.cosmosClient = new CosmosClient(this.cosmosConnectionString);
    }

    init = async function(userId) {
        if (this.database === null) {
            var response = await this.cosmosClient.databases.createIfNotExists({ id: this.cosmosDb + '_' + userId });
            this.database = response.database;
        }
    }

    getContainer = async function(id) {        
        var response = await this.database.containers.createIfNotExists({ id: id });
        return response.container;
    }

    query = async function(containerName, sql) {
        var container = await this.getContainer(containerName);
        var query = {
            query: sql
        };
        var q = await container.items.query(query).fetchAll();
      return q.resources;
    }

    getSelectedPlaylists = async function() {
        var items = await this.query('Playlists', 'SELECT * FROM c');
        if (items === undefined) items = [];
        return items;
    }

    addPlaylist = async function(playlist) {
        var container = await this.getContainer('Playlists');
        var item = {
            id: playlist.id,
            name: playlist.name
        }
        await container.items.create(item);
    }

    removePlaylist = async function(playlist) {
        var container = await this.getContainer('Playlists');
        await container.item(playlist.id).delete();
        return true;
    }

    getTrackData = async function(trackId) {
        var container = await this.getContainer('TrackData');
        var response = await container.item(trackId).read();
        if (response.statusCode !== 404) {
            return response.resource;
        }
        return null;
    }

    saveTrackData = async function(trackData) {
        var container = await this.getContainer('TrackData');
        var item = await this.getTrackData(trackData.id);
        if (item === null) {
            await container.items.create(trackData);
        } else {
            await container.item(trackData.id).replace(trackData);
        }
    }

}