<template>
    <div class="position-relative h-100 text-center mr-2 mb-2 p-2" style="width: 10rem;">
        <div v-if="withRemove" class="position-absolute top-0 end-0">
            <b-button size="sm" variant="danger" @click="removePlaylist"><b-icon icon="trash"/></b-button>
        </div>
        <img :src="img" class="bg-primary border border-light" style="width: 128px; height: 128px;" role="button" @click="onSelect">
        <b-row>
            <b-col>
                <span>{{ item.name }}</span><br/>
                <span class="small text-light">({{ item.tracks.total }} Tracks)</span>
            </b-col>
        </b-row>
    </div>
</template>
<script>
export default {
    name: 'item-playlist',
    data() {
        return {            
        }
    },
    props: {
        item: null,
        withRemove: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        img() {
            const vm = this;
            if (vm.item === null) return '';
            if (vm.item.images === undefined || vm.item.images === null) return '';
            if (vm.item.images.length === 0) return '';
            return vm.item.images[vm.item.images.length-1].url;
        }
    },
    methods: {
        onSelect() {
            const vm = this;
            vm.$emit('onSelect', vm.item);
        },
        removePlaylist() {
            const vm = this;
            vm.$bvModal.msgBoxConfirm('Soll die Spotify-Playlist -' + vm.item.name + '- aus der App entfernt werden? Die Playlist bleibt dabei in Spotify erhalten.', {
                title: 'Playlist entfernen',
                size: 'md',
                headerBgVariant: 'dark',
                bodyBgVariant: 'dark',
                footerBgVariant: 'dark',
                buttonSize: 'md',
                okVariant: 'danger',
                okTitle: 'Ja, Playlist entfernen',
                cancelVariant: 'primary',
                cancelTitle: 'Nein',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
            }).then(value => {
                if (value === true) {
                    vm.$emit('onRemove', vm.item);
                }
          });
        }
    }
}
</script>
<style scoped>
</style>