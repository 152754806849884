<template>
    <div>
        <b-alert :show="error !== null" variant="danger" class="mb-2">
            <b-icon icon="exclamation-triangle"/> {{ error }}
        </b-alert>        
        <contentbox text="Track-Daten bearbeiten" icon="pencil"
            :canRefresh="false"
            canClose @onClose="onClose">
            <b-row>
                <b-col>
                    <item-track :track="track" :allowEdit="false" :small="false" withAudioFeatures @onPlay="onPlay" />            
                </b-col>
            </b-row>           
            <b-row class="mt-3">
                <b-col>
                    <b-tabs>
                        <b-tab titleLinkClass="text-white">
                            <template slot="title">
                                <b-icon icon="bar-chart-steps"/> Meilensteine
                            </template>
                            <div v-if="milestones.length === 0" class="mt-3 text-center text-light">
                                <b-icon icon="info-circle"/><br/>
                                Es wurde noch keine Meilensteine angelegt.
                            </div>
                            <b-row v-for="m in orderedMilestones" v-bind:key="m.id" class="align-items-center ml-1 mr-1 mb-1 p-2 border border-light border-top-0 border-left-0 border-right-0 border-bottom-1" style="font-size: 1.25rem;">
                                <b-col cols="*" class="mr-3">
                                    <b-button variant="secondary" @click="editMilestone(m)"><b-icon icon="pencil"/></b-button>
                                </b-col>                           
                                <b-col cols="*" class="mr-3">
                                    <b-button variant="danger" @click="removeMilestone(m)"><b-icon icon="trash"/></b-button>
                                </b-col>                        
                                <b-col cols="*" class="mr-3">
                                    <span>{{ getMilestoneTime(m) }}</span>
                                </b-col>
                                <b-col v-if="m.icon !== null" cols="*">
                                    <img :src="getMilestoneIcon(m)" style="width: 32px; height: 32px;"/>
                                </b-col>                                 
                                <b-col>
                                    <span>{{ m.text }}</span>
                                </b-col>                        
                            </b-row>
                            <b-row class="mt-3">
                                <b-col class="text-center">
                                    <b-button variant="primary" @click="createNewMilestone"><b-icon icon="plus-circle"/> Meilenstein hinzufügen</b-button>
                                </b-col>
                            </b-row>   
                        </b-tab>
                        <b-tab v-if="trackData !== null" titleLinkClass="text-white">
                            <template slot="title">
                                <b-icon icon="info-square"/> Kategorisierung
                            </template>
                            <div class="p-3">
                                <label for="intensity" class="mr-3">Intensität:</label>
                                <b-form-rating id="intensity" inline :stars="10" v-model="trackData.intensity" @change="saveTrackData"></b-form-rating>
                                <span class="ml-3" style="font-size: 1.5rem;">{{ trackData.intensity }}</span>
                            </div>
                        </b-tab>
                    </b-tabs>
                </b-col>
            </b-row>            
        </contentbox>
        <modal-dialog
            v-model="showMilestone"
            :title="dialogTitle"
            @ok="applyMilestone">
            <div v-if="selectedMilestone !== null">
                <b-form>
                    <b-form-group label="Zeitpunkt:" label-for="ms-zeit-min" label-cols="3" description="Zu welchem Zeitpunkt tritt der Meilenstein ein?">                    
                        <b-row class="align-items-center ml-0">
                            <b-col cols="*">
                                <b-form-input id="ms-zeit-min" v-model="milestoneMin" style="width: 6rem;" type="number" :min="0" :max="99" :state="stateMilestoneMin"/>
                            </b-col>
                            <b-col cols="*" class="pl-2 pr-2">
                                <span>:</span>
                            </b-col>
                            <b-col cols="*">
                                <b-form-input id="ms-zeit-sec" v-model="milestoneSec" style="width: 6rem;" type="number" :min="0" :max="59" :state="stateMilestoneSec"/>
                            </b-col>
                            <b-col>
                                <span class="small">Minuten : Sekunden</span>
                            </b-col>
                        </b-row>
                    </b-form-group>       
                    <b-form-group label="Ankündigung Sekunden:" label-for="ms-zeit-ank" label-cols="3" description="Wieviele Sekunden vorher soll der Meilenstein angekündigt werden?">                    
                        <b-row class="align-items-center ml-0">
                            <b-col cols="*">
                                <b-form-input id="ms-zeit-ank" v-model="selectedMilestone.announce" style="width: 6rem;" type="number" :min="0" :max="99" :state="stateMilestoneAnnounce"/>
                            </b-col>
                            <b-col>
                                    <span class="small">Sekunden</span>
                                </b-col>                            
                        </b-row>
                    </b-form-group>                          
                    <b-form-group label="Symbol:" label-for="ms-text" label-cols="3" description="Welches Symbol soll beim Erreichen des Meilensteins eingeblendet werden?"> 
                        <b-dropdown variant="dark" v-model="selectedMilestone.icon">
                            <template slot="button-content">
                                <span v-if="selectedMilestone.icon !== null"><img :src="getMilestoneIcon(selectedMilestone)" style="width: 24px; height: 24px"/></span>
                            </template>
                            <b-dropdown-item v-for="i in availableIcons" v-bind:key="i.file" @click="selectMilestoneIcon(i)">
                                <b-row class="align-items-center">
                                    <b-col cols="*">
                                        <img :src="i.src" style="width: 24px; height: 24px;"/>
                                    </b-col>
                                    <b-col>
                                        <span class="small">{{ i.name }}</span>
                                    </b-col>                                    
                                </b-row>
                            </b-dropdown-item>
                        </b-dropdown>
                    </b-form-group>                    
                    <b-form-group label="Info-Text:" label-for="ms-text" label-cols="3" description="Informativer Text zum Meilenstein.">                    
                        <b-form-input id="ms-text" v-model="selectedMilestone.text" :state="stateMilestoneText"/>
                    </b-form-group>
                    <div style="height: 5rem;">&nbsp;</div>
                </b-form>
            </div>
        </modal-dialog>
    </div>
</template>
<script>
import contentbox from './contentbox.vue';
import modalDialog from './modal-dialog.vue';
import itemTrack from './items/item-track.vue';
export default {    
    name: 'track-edit',
    components: {
        contentbox,
        itemTrack,
        modalDialog
    },
    data() {
        return {   
            error: null,
            audioFeatures: null,
            loading: false,
            saving: false,
            trackData: null,
            isNewMilestone: false,
            selectedMilestone: null,
            showMilestone: false,
            milestoneValidationErrors: []
        }
    },
    props: {
        value: null
    },
    computed: {
        spotify() {
            const vm = this;
            return vm.$spotify;
        },
        track: {
            get() {
                const vm = this;
                return vm.value;
            },
            set(v) {
                const vm = this;
                vm.$emit('input', v);
            }
        },
        isCurrentTrack() {
            const vm = this;
            var currentTrack = vm.$store.getters['getCurrentTrack'];
            if (currentTrack === null) return false;
            if (currentTrack.id === vm.track.id) {
                return true;
            }
            if (currentTrack.linked_from !== null && currentTrack.linked_from !== undefined && currentTrack.linked_from.id === vm.track.id) {
                return true;
            }
            return false;
        },        
        milestones() {
            const vm = this;
            if (vm.trackData === null) return [];
            return vm.trackData.milestones;
        },
        orderedMilestones() {
            const vm = this;
            return vm.milestones.sort((a, b) => (a.time > b.time) ? 1 : -1)
        },
        availableIcons() {
            const vm = this;
            if (vm.$icons === undefined) return [];
            return vm.$icons.collection;
        },
        dialogTitle() {
            const vm = this;
            if (vm.isNewMilestone) return 'Meilenstein hinzufügen';
            return 'Meilenstein bearbeiten';
        },
        milestoneMin: {
            get() {
                const vm = this;
                if (vm.selectedMilestone === null) return 0;
                return parseInt(vm.$moment(vm.selectedMilestone.time).format('m'));
            },
            set(v) {
                const vm = this;
                if (vm.selectedMilestone === null) return;
                var time = ((parseInt(v) * 60) + parseInt(vm.milestoneSec)) * 1000;
                vm.selectedMilestone.time = time;
            }
        },
        milestoneSec: {
            get() {
                const vm = this;
                if (vm.selectedMilestone === null) return 0;
                return parseInt(vm.$moment(vm.selectedMilestone.time).format('s'));            
            },
            set(v) {
                const vm = this;
                if (vm.selectedMilestone === null) return;
                var time = ((parseInt(vm.milestoneMin) * 60) + parseInt(v)) * 1000;
                vm.selectedMilestone.time = time;                
            }
        },
        stateMilestoneMin() {
            const vm = this;
            if (vm.selectedMilestone === null) return false;
            if (vm.milestoneMin === '' || vm.milestoneMin === null || (vm.milestoneMin === 0 && vm.milestoneSec === 0)) return false;
            return true;
        },
        stateMilestoneSec() {
            const vm = this;
            if (vm.selectedMilestone === null) return false;
            if (vm.milestoneSec === '' || vm.milestoneSec === null || (vm.milestoneMin === 0 && vm.milestoneSec === 0) || vm.milestoneSec > 59) return false;
            return true;            
        },
        stateMilestoneAnnounce() {
            const vm = this;
            if (vm.milestoneAnnounce === null) return false;
            if (vm.milestoneSec > 60) return false;
            return true;            
        },        
        stateMilestoneSymbol() {
            const vm = this;
            if (vm.selectedMilestone === null) return false;
            if (vm.selectedMilestone.icon === null) return false;
            return true;
        },
        stateMilestoneText() {
            const vm = this;
            if (vm.selectedMilestone === null) return false;
            if (vm.selectedMilestone.text === '' || vm.selectedMilestone.text === null) return false;
            return true;
        },                        
    },
    mounted() {
        const vm = this;
        vm.loadTrackData();
    },   
    methods: {
        onClose() {
            const vm = this;
            vm.$emit('onClose');
        },
        getMilestoneIcon(m) {
            const vm = this;
            var icon = vm.$icons.collection.find(x => x.id === m.icon);
            if (icon === undefined) return '';
            return icon.src;
        },
        getMilestoneTime(m) {
            const vm = this;
            var ms = m.time;
            var minSec = vm.$moment.utc(ms).format('mm:ss');
            return minSec;
        },
        createNewMilestone() {
            const vm = this;
            vm.milestoneMin = 0;
            vm.milestoneSec = 0;
            var time = 0;
            if (vm.isCurrentTrack) {
                time = vm.$store.getters['getCurrentPos'];
            }
            var milestone = {
                id: vm.newId(),
                time: time,
                announce: 10,
                icon: '_info',
                text: ''
            };
            vm.selectedMilestone = milestone;
            vm.isNewMilestone = true;
            vm.showMilestone = true;
        },
        editMilestone(m) {
            const vm = this;
            vm.isNewMilestone = false;
            vm.selectedMilestone = vm.cloneObject(m);            
            vm.showMilestone = true;
        },
        cloneObject(obj) {
            var clone = {};
            for(let prop in obj) {
                clone[prop] = obj[prop];                
            }            
            return clone;
        },
        validateMilestone() {
            const vm = this;
            vm.milestoneValidationErrors = [];
            if (vm.selectedMilestone === null) return;
            if (vm.selectedMilestone.icon === null) {
                vm.milestoneValidationErrors.push("Bitte ein Symbol auswählen.");
            }
            if (vm.milestoneValidationErrors.time > vm.track.duration_ms) {
                vm.milestoneValidationErrors.push("Der Zeitpunkt liegt außerhalb der Song-Dauer.");
            }
            if (vm.milestoneValidationErrors.text === '' || vm.milestoneValidationErrors.text === null) {
                vm.milestoneValidationErrors.push("Bitte einen Info-Text eingeben.");
            }            
        },
        async applyMilestone(bvModalEvent) {
            const vm = this;            
            vm.validateMilestone();
            if (vm.milestoneValidationErrors.length === 0) {   
                if (vm.isNewMilestone) {
                    vm.trackData.milestones.push(vm.selectedMilestone);
                } else {
                    var item = vm.milestones.find(x => x.id === vm.selectedMilestone.id);
                    if (item !== undefined) {
                        for(let prop in item) {
                            item[prop] = vm.selectedMilestone[prop];                
                        }                        
                    }
                }
                vm.selectedMilestone = null;
                await vm.saveTrackData();
                return;
            }   
            bvModalEvent.preventDefault();   
        },
        removeMilestone(m) {
            const vm = this;
            var index = vm.trackData.milestones.indexOf(m);
            if (index > -1) vm.trackData.milestones.splice(index, 1);
            vm.saveTrackData();
        },
        selectMilestoneIcon(i) {
            const vm = this;
            vm.selectedMilestone.icon = i.id;
            if (i.id !== '_info') vm.selectedMilestone.text = i.name;
        },
        newId() {
            return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
                (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
            );
        },
        onPlay() {
            const vm = this;
            vm.$spotify.playTrack(vm.track.uri, vm.$store.state.spotifyDeviceId )
        },
        async loadTrackData() {
            const vm = this;
            vm.loading = true;
            try {
                vm.trackData = await vm.$cloud.getTrackData(vm.track.id);                
                if (vm.trackData === null) {
                    vm.trackData = {
                        id: vm.track.id,
                        milestones: []
                    }
                }
                if (vm.trackData.intensity === undefined) vm.trackData.intensity = "5";
                if (vm.isCurrentTrack) vm.$store.commit('setCurrentMilestones', vm.trackData.milestones);
                vm.loading = false;
            } catch(err) {
                vm.loading = false;
                vm.error = err;
            }
        },
        async saveTrackData() {
            const vm = this;
            vm.saving = true;
            try {
                if (vm.isCurrentTrack) vm.$store.commit('setCurrentMilestones', vm.trackData.milestones);                
                await vm.$cloud.saveTrackData(vm.trackData);
                vm.saving = false;
            } catch(err) {
                vm.saving = false;
                vm.error = err;
            }
        }
    } 
}
</script>
<style scoped>
</style>