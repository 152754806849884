<template>
    <div :class="'pt-1 pb-1 text-white mb-0 border border-light border-top-0 border-left-0 border-right-0 border-bottom-1 ' + bgVariant" :style="'font-size: ' + fontSize + 'rem;'">
        <b-row class="align-items-center p-0 m-0">       
            <b-col cols="*" class="text-left ml-0 mr-3">
                <img :src="img" class="bg-primary border border-white" style="width: 64px; height: 64px;">
            </b-col>      
            <b-col cols="*">
                <b-button variant="primary" @click="onPlay" :disabled="isCurrentTrack"><b-icon icon="play-circle" font-scale="1.5"/></b-button>
            </b-col>                          
            <b-col class="text-left border border-light border-left-0 border-top-0 border-bottom-0 border-right-1">
                <span>{{ track.name }}</span><br/>
                <span class="text-light">{{ artists }}</span>
            </b-col>
            <b-col cols="*" class="text-center ml-3">
                <div>
                    <b-icon icon="clock" class="mr-1"/>
                    <span v-if="!isCurrentTrack">{{ duration }}</span>
                    <span v-else>{{ currentPos }}</span>
                </div>
                <div v-if="duration !== cumulatedDuration">
                    <b-icon icon="clock-history" class="mr-1 text-light"/>
                    <span class="text-light" v-if="!isCurrentTrack">{{ cumulatedDuration }}</span>
                    <span class="text-light" v-else>{{ currentPos }}</span>
                </div>                
            </b-col>            
            <b-col cols="*" class="text-center ml-3" :style="'font-size: ' + fontSize + 'rem; min-width: 5rem;'">
                <span v-if="!loadingTrackData"><b-icon icon="music-note-beamed"/> {{ bpm }}</span><span v-if="!loadingTrackData" class="text-light ml-1" style="font-size: 0.8rem;">({{bpmHalf }})</span>
                <b-spinner v-if="loadingTrackData"/>
            </b-col>
            <b-col cols="*" class="text-center p-2">
                <b-badge v-if="!loadingTrackData && trackData !== null && trackData.milestones.length > 0" pill variant="secondary"><span :style="'font-size: ' + fontSize + 'rem;'"><b-icon icon="bar-chart-steps"/> {{ milestones.length }}</span><span v-if="!small"> Meilensteine</span></b-badge>
                <b-badge v-if="!loadingTrackData && (trackData === null || trackData.milestones.length === 0)" pill variant="black"><span :style="'font-size: ' + fontSize + 'rem;'"><b-icon icon="bar-chart-steps"/> {{ milestones.length }}</span><span v-if="!small"> Meilensteine</span></b-badge>
                <b-spinner v-if="loadingTrackData"/>
            </b-col>                                           
            <b-col cols="*" v-if="allowEdit" class="mr-3">
                <b-button variant="secondary" @click="onEdit"><b-icon icon="pencil" font-scale="1.5"/></b-button>
            </b-col>        
            <b-col cols="*" v-if="allowRemove" class="mr-3">
                <b-button variant="danger" @click="onRemove"><b-icon icon="trash" font-scale="1.5"/></b-button>
            </b-col>                     
        </b-row>
    </div>
</template>
<script>
export default {
    name: 'item-track',
    data() {
        return {       
            error: null,
            loadingTrackData: false,
            trackData: null
        }
    },
    props: {
        track: null,
        playlistDurationBeforeTrack: {
            type: Number,
            default: 0
        },
        allowEdit: {
            type: Boolean,
            default: true
        },
        allowRemove: {
            type: Boolean,
            default: false
        },        
        small: {
            type: Boolean,
            default: true
        }
    },
    computed: {
        bgVariant() {
            const vm = this;
            if (!vm.isCurrentTrack) return '';
            return 'bg-primary';
        },
        fontSize() {
            const vm = this;
            if (vm.small) return "1";
            return "1.25";
        },
        img() {
            const vm = this;
            if (vm.track === null || vm.track.album === null || vm.track.album.images === null) return '';
            if (vm.track.album.images.length === 0) return '';
            return vm.track.album.images[vm.track.album.images.length-1].url;
        },        
        artists() {
            const vm = this;
            var artists = '';
            vm.track.artists.forEach(a => {
                if (artists !== '') artists += ', ';
                artists += a.name;
            });
            return artists;
        },
        duration() {
            const vm = this;
            return vm.$moment.utc(vm.track.duration_ms).format('mm:ss');
        },
        cumulatedDuration() {
            const vm = this;
            let duration = vm.playlistDurationBeforeTrack + vm.track.duration_ms;
            return vm.$moment.utc(duration).format('mm:ss');
        },        
        isCurrentTrack() {
            const vm = this;
            var currentTrack = vm.$store.getters['getCurrentTrack'];
            if (currentTrack === null) return false;
            if (currentTrack.id === vm.track.id) {
                return true;
            }
            if (currentTrack.linked_from !== null && currentTrack.linked_from !== undefined && currentTrack.linked_from.id === vm.track.id) {
                return true;
            }
            return false;
        },
        bpm() {
            const vm = this;
            if (vm.trackData !== null && vm.trackData.bpm !== undefined && vm.trackData.bpm !== null) return Math.round(vm.trackData.bpm);
            return 0;
        },
        bpmHalf() {
            const vm = this;
            if (vm.trackData !== null && vm.trackData.bpm !== undefined && vm.trackData.bpm !== null) return Math.round(vm.trackData.bpm / 2);
            return 0;
        },        
        currentPos() {
            const vm = this;
            var currentPos = vm.$store.getters['getCurrentPos'];
            return vm.$moment(currentPos).format('mm:ss');
        },
        milestones() {
            const vm = this;
            if (vm.trackData === null) return [];
            return vm.trackData.milestones;
        }
    },
    mounted() {
        const vm = this;
        vm.loadTrackData();        
    },
    methods: {
        refreshDetails() {
            const vm = this;
            vm.milestones = [];
            if (vm.track === null) return;
        },
        async loadTrackData() {
            const vm = this;
            vm.trackData = null;
            vm.error = null;
            if (vm.track === null) return;
            vm.loadingTrackData = true;
            try {
                vm.trackData = await vm.$cloud.getTrackData(vm.track.id);
                vm.loadingTrackData = false;
            } catch(err) {
                vm.loadingTrackData = false;
                vm.error = err;
            }
        },        
        onEdit() {
            const vm = this;
            vm.$emit('onEdit', vm.track);
        },
        onRemove() {
            const vm = this;
            vm.$emit('onRemove', vm.track);
        },        
        onPlay() {
            const vm = this;
            vm.$emit('onPlay', vm.track);
        }        
    }
}
</script>
<style scoped>
</style>