<template>
    <b-container fluid class="bg-primary p-3 m-0">     
        <b-alert v-if="error !== null" show variant="danger" dismissible class="position-fixed fixed-top m-0 rounded-0" @dismissed="onErrorClose">
            <span><b-icon icon="exclamation-triangle"/> Fehler im Web-Player: {{ error }}</span>
        </b-alert>  
        <b-row class="align-items-center p-0 m-0">
            <b-col cols="*" class="mr-2" style="font-size: 2.5rem;">
                <b-icon icon="list" class="border border-light p-1"/>
            </b-col>
            <b-col cols="*" class="mr-1">
                <b-button variant="secondary" size="lg" :disabled="!prevPossible" @click="prev"><b-icon icon="chevron-bar-left" font-scale="2"/></b-button>
            </b-col>        
            <b-col v-if="!isPlaying" cols="*" class="mr-1">
                <b-button variant="secondary" size="lg" :disabled="!playPossible" @click="playResume"><b-icon icon="play-circle-fill" font-scale="2"/></b-button>
            </b-col>
            <b-col v-if="isPlaying" cols="*" class="mr-1">
                <b-button variant="secondary" size="lg" :disabled="!pausePossible" @click="pause"><b-icon icon="pause-circle-fill" font-scale="2"/></b-button>
            </b-col>    
            <b-col cols="*" class="mr-3" @click="next">
                <b-button variant="secondary" size="lg" :disabled="!nextPossible"><b-icon icon="chevron-bar-right" font-scale="2"/></b-button>
            </b-col>             
            <b-col cols="*">
                <img v-show="track !== null" :src="img" class="bg-primary border border-dark" style="width: 64px; height: 64px;" role="button">
            </b-col>            
            <b-col class="text-left" style="font-size: 1.5rem;">
                <div v-if="track !== null" class="font-weight-bold"><b-icon icon="music-note"/>
                    <span>{{ track.name }} - {{ getTrackArtists() }}</span><br/>
                    <span class="text-light"><b-icon icon="clock" class="mr-1"/> {{ trackDurationTime }}</span>                
                </div>
                <div v-else>(kein Track aktiv)</div>
            </b-col>               
            <b-col cols="*" class="text-center text-secondary">
                <span style="font-size: 1.5rem;" class="p-0 m-0"><b-icon icon="volume-up-fill"/>{{ volume }}%</span>             
                <vue-slide-bar
                    paddingless
                    :line-height="10"
                    v-model="volume"
                    :speed="1"
                    :min="0"
                    :max="100"
                    :is-disabled="muted"                    
                    :showTooltip="false"
                    :processStyle="{ backgroundColor: '#ccb695' }"
                    style="width: 20rem;">
                </vue-slide-bar>
            </b-col>
            <b-col cols="*" class="text-center ml-2">
                <b-button v-if="!muted" variant="primary" @click="toggleMute"><b-icon icon="volume-mute" font-scale="2"/></b-button>
                <b-button v-if="muted" variant="danger" @click="toggleMute"><b-icon icon="volume-mute" font-scale="2" animation="throb"/></b-button>
            </b-col>
        </b-row>        
        <b-row v-if="track !== null && enableAnalysis" class="mt-2 mb-0 p-0">
            <b-col>
                <audio-visualization v-model="audioAnalysis"/>
            </b-col>
        </b-row>
        <b-row v-if="track !== null" class="align-items-center mt-5 mb-5">
            <b-col>
                <vue-slide-bar
                    paddingless
                    :line-height="10"
                    v-model="currentPos"
                    :speed="0"
                    :min="0"
                    :is-disabled="false"
                    :max="trackDuration"
                    :processStyle="{ backgroundColor: '#ccb695' }"
                    @dragStart="onSliderDragStart"
                    @dragEnd="onSliderDragEnd">
                    <template slot="tooltip">
                        <b-badge pill variant="secondary">
                            <span v-if="!sliderDragging" style="font-size: 1.5rem;" class="text-dark p-2">{{ currentTime }}</span>
                            <span v-if="sliderDragging" style="font-size: 1.5rem;" class="text-dark p-2">{{ draggingTime }}</span>
                        </b-badge>
                    </template>
                </vue-slide-bar>
                <div class="w-100">
                    <div v-for="m in milestones" v-bind:key="m.id" class="milestone-marker" :style="'left: ' + getMilestonePercentOffset(m) + '%;'">
                        <img :id="'milestone-tooltip-target-' + m.id" :src="getMilestoneIcon(m)" class="milestone-icon"/>
                        <b-tooltip :target="'milestone-tooltip-target-' + m.id" variant="secondary" placement="bottom" triggers="hover click">
                            <span>{{ m.text }}</span>
                        </b-tooltip>
                    </div>
                </div>
            </b-col>            
        </b-row>
    </b-container> 
</template>
<script>
import VueSlideBar from 'vue-slide-bar';
import AudioVisualization from './audio-visualization.vue';
export default {
    name: 'player',
    components: {
        VueSlideBar,
        AudioVisualization
    },
    data() {
        return {      
            error: null,
            webPlayer: null,    
            webPlayerConnected: false,
            state: null,
            timeTracking: null, 
            lastStateTime: null,
            muted: false,
            volumeBeforeMute: 0,    
            sliderDragging: false,
            posBeforeDrag: 0,
            draggingPos: 0,
            seeked: false,
            enableAnalysis: false,
            audioAnalysis: null
        }
    },
    computed: {
        spotify() {
            const vm = this;
            return vm.$spotify;
        },
        track: {
            get() {
                const vm = this;
                return vm.$store.getters['getCurrentTrack'];
            }
        },      
        img() {
            const vm = this;
            if (vm.track === null || vm.track.album === null || vm.track.album.images === null) return '';
            if (vm.track.album.images.length === 0) return '';
            return vm.track.album.images[vm.track.album.images.length-1].url;
        },
        volume: {
            get() {
                const vm = this;
                return vm.$store.state.volume;
            },
            set(v) {
                const vm = this;
                vm.$store.state.volume = v;
            }
        },        
        currentPos: {
            get() {
                const vm = this;
                if (vm.sliderDragging) return vm.posBeforeDrag;
                var pos = vm.$store.getters['getCurrentPos'];
                if (pos > vm.trackDuration) {
                    pos = vm.trackDuration;
                    return pos;
                }
                return vm.$store.getters['getCurrentPos'];
            },
            set(v) {
                const vm = this;
                if (!vm.sliderDragging) {
                    vm.$store.commit('setCurrentPos', v);
                } else {
                    vm.draggingPos = v;
                }
            }
        },
        milestones: {
            get() {
                const vm = this;
                return vm.$store.getters['getCurrentMilestones'];
            },
            set(v) {
                const vm = this;
                vm.$store.commit('setCurrentMilestones', v);
            }
        },
        isPlaying() {
            const vm = this;
            if (vm.state === null) return false;
            return (vm.state.track_window.current_track !== undefined && vm.state.paused === false);
        },
        trackDuration() {
            const vm = this;
            if (vm.track === null) return 0;
            return vm.track.duration_ms;
        },
        trackDurationTime() {
            const vm = this;
            return vm.$moment.utc(vm.trackDuration).format('mm:ss');            
        },
        prevPossible() {
            const vm = this;
            return (vm.state !== null && vm.state.disallows.skipping_prev !== true);
        },
        playPossible() {
            const vm = this;
            return (vm.state !== null && vm.state.disallows.resuming !== true);
        },        
        pausePossible() {
            const vm = this;
            return (vm.state !== null && vm.state.disallows.pausing !== true);
        },   
        nextPossible() {
            const vm = this;
            return (vm.state !== null && vm.state.disallows.skipping_next !== true);
        },              
        currentTime() {
            const vm = this;
            return vm.$moment(vm.currentPos).format('mm:ss');
        },
        timeLeft() {
            const vm = this;
            var passed_ms = vm.trackDuration - vm.currentPos;
            return vm.$moment(passed_ms).format('mm:ss');
        },        
        draggingTime() {
            const vm = this;
            return vm.$moment(vm.draggingPos).format('mm:ss');
        }                      
    },
    watch: {
        state() {
            const vm = this;
            if (vm.timeTracking !== null)  {
                clearInterval(vm.timeTracking);            
            }
            if (vm.state === null) return;
            vm.$store.commit('setCurrentTrack', vm.state.track_window.current_track);
            if (vm.seeked) {
                vm.seeked = false;
                vm.playResume();
                return;
            }
            if (vm.state.paused  === false) {
                vm.lastStateTime = vm.$moment();
                vm.timeTracking = setInterval(() => {
                    if (vm.sliderDragging) {
                        clearInterval(vm.timeTracking);
                        return;
                    }
                    var now = vm.$moment();
                    var durationAfterLastState = now.diff(vm.lastStateTime);
                    vm.currentPos = vm.state.position + durationAfterLastState;
                }, 50);
            }
        },
        volume() {
            const vm = this;
            vm.$spotify.setVolume(vm.$store.state.spotifyDeviceId, vm.volume);
        },
        track() {
            const vm = this;
            vm.loadAudioAnalysis();
            vm.loadMilestones();
        }
    },
    methods: {
        init(player) {
            const vm = this;
            vm.webPlayerConnected = false;
            vm.webPlayer = player;            
            vm.error = null;
            // Error handling
            vm.webPlayer.addListener('initialization_error', ({ message }) => {
                vm.error = message;
            });
            vm.webPlayer.addListener('authentication_error', ({ message }) => {
                vm.error = message;
            });
            vm.webPlayer.addListener('account_error', ({ message }) => {
                vm.error = message;
            });
            vm.webPlayer.addListener('playback_error', ({ message }) => {
                vm.error = message;
            });
        
            // Playback status updates
            vm.webPlayer.addListener('player_state_changed', state => {
                vm.state = state;
            });
        
            // Ready
            vm.webPlayer.addListener('ready', ({ device_id }) => {
                vm.$store.state.spotifyDeviceId = device_id;
                vm.webPlayerConnected = true;
                //vm.startTracking();
            });
        
            // Not Ready
            vm.webPlayer.addListener('not_ready', () => {                
                vm.$store.state.spotifyDeviceId = null;
                vm.webPlayerConnected = false;                
            });
        
            // Connect to the player!
            vm.webPlayer.connect();                  
        },
        getTrackArtists() {
            const vm = this;
            if (vm.track === null) return null;
            var artists = '';
            vm.track.artists.forEach(a => {
                if (artists !== '') artists += ', ';
                artists += a.name;
            });
            return artists;            
        },
        onErrorClose() {
            const vm = this;
            vm.error = null;
        },
        toggleMute() {
            const vm = this;
            if (!vm.muted) {
                vm.volumeBeforeMute = vm.volume;
            }
            vm.muted = !vm.muted;
            if (vm.muted) vm.volume = 0;
            if (!vm.muted) vm.volume = vm.volumeBeforeMute;
        },        
        getMilestonePercentOffset(m) {
            const vm = this;
            return parseInt(((m.time / vm.trackDuration) * 100));
        },
        playResume() {
            const vm = this;
            vm.error = null;
            vm.spotify.play(null, null, vm.$store.state.spotifyDeviceId).then(() => {
            }).catch(err => {
                vm.error = err;                    
            });
        },
        pause() {
            const vm = this;
            vm.error = null;
            vm.spotify.pause(vm.$store.state.spotifyDeviceId).then(() => {
            }).catch(err => {
                vm.error = err;                    
            });
        },
        prev() {
            const vm = this;
            vm.error = null;
            vm.spotify.previous(vm.$store.state.spotifyDeviceId).then(() => {
            }).catch(err => {
                vm.error = err;                    
            });
        },        
        next() {
            const vm = this;
            vm.error = null;
            vm.spotify.next(vm.$store.state.spotifyDeviceId).then(() => {
            }).catch(err => {
                vm.error = err;                    
            });
        },
        onSliderDragStart() {
            const vm = this;
            vm.posBeforeDrag = vm.currentPos;
            vm.draggingPos = vm.posBeforeDrag;
            vm.sliderDragging = true;
        },
        onSliderDragEnd() {
            const vm = this;
            vm.sliderDragging = false;                 
            vm.pause();
            vm.currentPos = vm.draggingPos;
            vm.$spotify.seek(vm.$store.state.spotifyDeviceId, vm.draggingPos);    
            vm.seeked = true;            
        },
        async loadMilestones() {            
            const vm = this;
            if (vm.track === null) {
                vm.milestones = [];
                return;
            }
            var trackData = await vm.$cloud.getTrackData(vm.track.id);
            if (trackData === null) {
                vm.milestones = [];
                return;
            }
            vm.milestones = trackData.milestones;
        },
        loadAudioAnalysis() {
            const vm = this;
            if (vm.track === null) return;
            vm.$spotify.getAudioAnalysis(vm.track.id).then(data => {
                vm.audioAnalysis = data;                    
            });
        },
        getMilestoneIcon(m) {
            const vm = this;
            var id = '_info';
            if (m.icon !== undefined) {
                id = m.icon;
            }
            var icon = vm.$icons.collection.find(x => x.id === id);
            if (icon === undefined) return '';
            return icon.src;
        }         
    }

}
</script>
<style scoped>
.milestone-marker {
    text-align: center;
    position: absolute;
    width: 64px;
    height: 96px;
    margin-top: -36px;
    margin-left: -24px;
    background-image: url('../assets/img/milestones/marker.png'); 
    z-index: 9999;
}
.milestone-icon {
    width: 48px;
    height: 48px;
    margin-top: 40px;
}
</style>