<template>
    <div class="h-100 ml-3 mr-3 pt-3">
        <b-alert :show="error !== null" variant="danger" class="mb-2">
            <b-icon icon="exclamation-triangle"/> {{ error }}
        </b-alert>
        <contentbox v-if="selectedPlaylist === null && !playlistsLoading"
            :text="'Meine Playlists (' + appPlaylists.length + ')'"
            icon="music-note-list"
            @onRefresh="loadPlaylists">
            <b-container fluid class="h-100 p-0">
                <b-row>
                    <b-col>
                        <b-button variant="success" @click="showAddPlaylist = true" class="mb-2"><b-icon icon="plus-circle"/> Playlist hinzufügen...</b-button>
                    </b-col>
                </b-row>
                <b-row v-if="playlistsLoading">
                    <b-col>
                        <b-spinner/>
                        <div v-if="appPlaylists.length === 0" class="text-light mb-3">
                            <span><b-icon icon="info-circle"/> Du hast noch keine Spotfiy-Playlists hinzugefügt.</span>
                        </div>
                    </b-col>
                </b-row>
                <b-container fluid>
                    <b-row>
                        <b-col cols="*" v-for="pl in appPlaylists" v-bind:key="pl.id">
                            <item-playlist 
                                withRemove
                                :item="pl" @onSelect="onPlaylistSelect"
                                @onRemove="onRemovePlaylist"/>
                        </b-col>
                    </b-row>
                </b-container>
                <modal-dialog v-model="showAddPlaylist"
                    title="Playlist hinzufügen"
                    size="xl"
                    onlyClose>
                    <b-container fluid>
                        <b-row>
                            <b-col cols="*" v-for="pl in selectablePlaylists" v-bind:key="pl.id">
                                <item-playlist :item="pl" @onSelect="onAddPlaylist"/>
                            </b-col>
                        </b-row>
                    </b-container>
                </modal-dialog>
            </b-container>
        </contentbox>
        <playlist v-if="selectedPlaylist !== null"
            v-model="selectedPlaylist"
            @onClose="onPlaylistClose"/>
    </div>
</template>
<script>
import itemPlaylist from './items/item-playlist.vue';
import contentbox from './contentbox.vue';
import playlist from './playlist.vue';
import modalDialog from './modal-dialog.vue';
export default {
    name: 'home',
    components: {
        modalDialog,
        contentbox,
        itemPlaylist,
        playlist
    },
    data() {
        return {
            error: null,
            appPlaylists: [],
            playlistsLoading: false,
            playlists: [],
            selectedPlaylist: null,
            showAddPlaylist: false,   
        }
    },
    mounted() {
        const vm = this;
        vm.loadPlaylists();
    },
    computed: {
        spotify() {
            const vm = this;
            return vm.$spotify;
        },
        spotifyProfile() {
            const vm = this;
            return vm.$store.state.spotifyProfile;
        },
        selectablePlaylists() {
            const vm = this;
            var items = [];
            vm.playlists.forEach(pl => {
                if (vm.appPlaylists.indexOf(pl) === -1) {
                    items.push(pl);
                }
            });
            return items;
        }
    },
    methods: {
        async loadPlaylists() {
            const vm = this;   
            vm.playlists = [];
            vm.appPlaylists = [];
            var cloudPlaylists = await vm.$cloud.getSelectedPlaylists();
            vm.playlistsLoading = true;
            vm.spotify.getPlaylists(vm.spotifyProfile).then(data => {     
                vm.playlists = data;
                vm.playlists.forEach(pl => {
                    if (cloudPlaylists.find(x => x.id === pl.id) !== undefined) {
                        vm.appPlaylists.push(pl);
                    }
                });
                vm.playlistsLoading = false;
            }).catch((err) => {
                vm.playlistsLoading = false;
                vm.error = err;
            });
        },
        onPlaylistSelect(playlist) {
            const vm = this;
            vm.selectedPlaylist = playlist;
        },
        onPlaylistClose() {
            const vm = this;
            vm.selectedPlaylist = null;
        },     
        async onAddPlaylist(playlist) {
            const vm = this;
            await vm.$cloud.addPlaylist(playlist);
            vm.appPlaylists.push(playlist);
            vm.showAddPlaylist = false;
        },
        async onRemovePlaylist(playlist) {
            const vm = this;
            var deleteOk = await vm.$cloud.removePlaylist(playlist);
            if (deleteOk === true) {
                var index = vm.appPlaylists.indexOf(playlist);
                if (index > -1) vm.appPlaylists.splice(index, 1);
            }

        }        
    }
}
</script>
<style scoped>
</style>