<template>
    <b-container fluid class="h-100" style="overflow-y: auto; overflow-x: hidden;">
        <b-row class="align-items-center text-light border border-light border-top-0 border-left-0 border-right-0 border-bottom-1">
            <b-col cols="*" v-if="canClose">
                <b-button variant="black" @click="onClose"><b-icon icon="arrow-left-circle" :font-scale="1.5"/></b-button>
            </b-col>            
            <b-col cols="*" v-if="canRefresh">
                <b-button variant="black" @click="onRefresh"><b-icon icon="arrow-clockwise" :font-scale="1.5"/></b-button>
            </b-col>                        
            <b-col cols="*">
                <b-icon v-if="icon !== null" :icon="icon" :font-scale="1.5" class="ml-2"/>
            </b-col>
            <b-col>
                <h4>{{ text }}</h4>
            </b-col>
        </b-row>
        <b-row>
            <b-col class="pt-3 pl-0 pr-0">
                <b-container fluid>
                    <b-row class="mb-2">
                        <b-col cols="*">
                            <b-button v-if="canAdd"  variant="success" @click="onAdd"><b-icon icon="plus-circle" :font-scale="1"/><span v-if="addLabel !== null"> {{ addLabel }}</span></b-button>                       
                        </b-col>
                        <b-col>
                            <slot name="buttons"></slot> 
                        </b-col>
                    </b-row>     
                </b-container>           
                <slot/>
            </b-col>
        </b-row>
    </b-container>
</template>
<script>
export default {
    name: 'contentbox',
    data() {
        return {            
        }
    },
    props: {
        text: {
            type: String,
            required: true
        },
        icon: {
            type: String,
            default: null
        },
        canRefresh: {
            type: Boolean,
            default: true
        },                
        canAdd: {
            type: Boolean,
            default: false
        },
        addLabel: {
            type: String,
            default: null
        },
        canClose: {
            type: Boolean,
            default: false
        }    
    },
    methods: {
        onClose() {
            const vm = this;
            vm.$emit('onClose');
        },
        onRefresh() {
            const vm = this;
            vm.$emit('onRefresh');
        },
        onAdd() {
            const vm = this;
            vm.$emit('onAdd');
        }                         
    }
}
</script>
<style scoped>
</style>