import iconInfo from './assets/img/milestones/icon_info.png';
import iconFreestyle from './assets/img/milestones/spinning/icon_freestyle.png';
import iconSeatedFlat from './assets/img/milestones/spinning/icon_seated_flat.png';
import iconStandingFlat from './assets/img/milestones/spinning/icon_standing_flat.png';
import iconSeatedClimb from './assets/img/milestones/spinning/icon_seated_climb.png';
import iconRunningWithResistance from './assets/img/milestones/spinning/icon_running_with_resistance.png';
import iconJumps from './assets/img/milestones/spinning/icon_jumps.png';
import iconStandingClimb from './assets/img/milestones/spinning/icon_standing_climb.png';
import iconJumpsOnAHill from './assets/img/milestones/spinning/icon_jumps_on_a_hill.png';
import iconSprints from './assets/img/milestones/spinning/icon_sprints.png';
import iconSprintsOnAHill from './assets/img/milestones/spinning/icon_sprints_on_a_hill.png';
export default {
    collection: [
        { id: '_info', name: 'Info', src: iconInfo },
        { id: '_spinning_iconFreestyle', name: 'Freestyle', src: iconFreestyle },
        { id: '_spinning_iconSeatedFlat', name: 'Seated flat', src: iconSeatedFlat },
        { id: '_info_spinning_iconStandingFlat', name: 'Standing flat', src: iconStandingFlat },
        { id: '_info_spinning_iconSeatedClimb', name: 'Seated climb', src: iconSeatedClimb },
        { id: '_info_spinning_iconRunningWithResistance', name: 'Running with resistance', src: iconRunningWithResistance },
        { id: '_info_spinning_iconJumps', name: 'Jumps', src: iconJumps },
        { id: '_info_spinning_iconStandingClimb', name: 'Standing climb', src: iconStandingClimb },
        { id: '_info_spinning_iconJumpsOnAHill', name: 'Jumps on a hill', src: iconJumpsOnAHill },
        { id: '_info_spinning_iconSprints', name: 'Sprints', src: iconSprints },
        { id: '_info_spinning_iconSprintsOnAHill', name: 'Sprints on a hill', src: iconSprintsOnAHill }
    ]   
}