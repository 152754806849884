<template>
    <b-container v-if="currentTrack !== null && playlistTracks !== null" fluid class="h-100 bg-primary p-3 text-center">
        <h3><b-icon icon="info-circle"/> Belastungsprofil</h3>
        <b-row v-if="playlistTracks !== null" class="mt-3">
            <b-col>
                <b-progress variant="secondary" :max="totalTracks" height="2rem">
                    <b-progress-bar :value="currentTrackNr">                        
                    </b-progress-bar>
                </b-progress>
            </b-col>
        </b-row>
        <b-row class="align-items-center m-0">
            <b-col cols="*" style="font-size: 2.5rem;">
                <b-badge class="bg-secondary text-dark">
                    <b-icon icon="arrow-left"/>
                    <span> {{ formatLongDuration(currentPosPlaylist) }}</span>
                </b-badge>
            </b-col>
            <b-col class="text-secondary" style="font-size: 1.5rem;">
                <b-badge class="bg-secondary text-dark"><b-icon icon="music-note-list"/> Track {{ currentTrackNr }} von {{ totalTracks }}</b-badge>
            </b-col>
            <b-col cols="*" style="font-size: 2.5rem;">
                <b-badge class="bg-secondary text-dark">
                    <span>{{ formatLongDuration(leftTimePlaylist) }} </span>
                    <b-icon icon="clock"/>                     
                </b-badge>
            </b-col>                        
        </b-row>
        <b-row v-if="currentMilestone !== null || announceNextMilestone" class="p-3 mt-3 border border-white border-top-1 border-left-0 border-right-0 border-bottom-0">
            <b-col>
                <b-row class="align-items-center">
                    <b-col v-if="currentMilestone !== null" class="text-center">
                        <img :src="getMilestoneIcon(currentMilestone)" style="width: 10rem; height: 10rem;"/><br/>
                        <span style="font-size: 2rem;">{{ currentMilestone.text }}</span>
                    </b-col>
                    <b-col v-if="announceNextMilestone" cols="*" class="text-center">
                        <b-col cols="*" style="font-size: 2.5rem;">
                            <span class="text-secondary"><b-icon icon="exclamation-triangle" font-scale="3" animation="throb"/></span><br/>
                            <b-badge class="bg-secondary text-dark">
                                <b-icon icon="arrow-right"/><b-icon icon="clock"/> <span>{{ $moment(timeToNextMilestone).format('mm:ss') }}</span><b-icon icon="arrow-right"/>
                            </b-badge>
                        </b-col>                        
                    </b-col>
                    <b-col v-if="announceNextMilestone" class="text-center">
                        <img :src="getMilestoneIcon(nextMilestone)" style="width: 10rem; height: 10rem;"/><br/>
                        <span style="font-size: 2rem;">{{ nextMilestone.text }}</span>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>      
    </b-container>
</template>
<script>
export default {
    name: 'session-info',
    data() {
        return {            
        }
    },
    props: {
        playlistTracks: null
    },
    computed: {
        currentTrackNr() {
            const vm = this;
            var currentTrack = vm.currentTrack;
            if (vm.playlistTracks === null || currentTrack === null) return 0;
            var track = vm.playlistTracks.find(x => x.track.id === currentTrack.id || (currentTrack.linked_from !== null && currentTrack.linked_from.id === x.track.id));
            if (track === undefined) return 0;
            var index = vm.playlistTracks.indexOf(track);
            return index+1;
        },
        totalTracks() {
            const vm = this;
            if (vm.playlistTracks === null) return 0;
            return vm.playlistTracks.length;
        },
        currentTrack() {
            const vm = this;
            var track = vm.$store.getters['getCurrentTrack'];   
            if (track === null) return null;
            return track;
        },
        currentPos() {
            const vm = this;
            return vm.$store.getters['getCurrentPos'];            
        },
        leftTimePlaylist() {
            const vm = this;
            return parseInt(vm.durationPlaylist - vm.currentPosPlaylist);
        },
        duration() {
            const vm = this;
            if (vm.currentTrack === null) return 0;
            return parseInt(vm.currentTrack.duration_ms);
        },
        currentPosPlaylist() {
            const vm = this;
            var pos = 0;
            if (vm.playlistTracks === null) return pos;
            var index = 0;
            var currentTrackNr = vm.currentTrackNr;
            if (currentTrackNr === 0) return pos;
            var currentTrackReached = false;
            while(!currentTrackReached)
            {
                if ((currentTrackNr-1) > index) {
                    var t = vm.playlistTracks[index];
                    // Track is completed
                    pos = pos + t.track.duration_ms;
                }
                if ((currentTrackNr-1) === index) {
                    // Track is playing, only add current song position
                    pos = pos + vm.currentPos;
                    currentTrackReached = true;
                }                
                index++;
            }
            return pos;
        },
        durationPlaylist() {
            const vm = this;
            var duration = 0;
            if (vm.playlistTracks === null) return 0;
            vm.playlistTracks.forEach(t => {
                duration += t.track.duration_ms;
            });
            return duration;
        },
        milestones() {
            const vm = this;
            return vm.$store.getters['getCurrentMilestones'];
        },
        currentMilestone() {
            const vm = this;
            var items = vm.milestones.filter(x => x.time <= vm.currentPos);
            if (items.length === 0) return null;
            return items[items.length-1];
        },  
        announceNextMilestone() {
            const vm = this;
            if (vm.nextMilestone === null) return false;
            if (vm.nextMilestone.announce === undefined || vm.nextMilestone.announce === 0) return false;
            var ms = parseInt(vm.nextMilestone.announce * 1000);
            return (vm.currentPos >= vm.nextMilestone.time - ms);
        },
        timeToNextMilestone() {
            const vm = this;
            if (vm.nextMilestone === null) return false;
            if (vm.nextMilestone.announce === undefined || vm.nextMilestone.announce === 0) return false;
            return parseInt((vm.nextMilestone.time) - vm.currentPos);
        },        
        nextMilestone() {
            const vm = this;
            var items = vm.milestones.filter(x => x.time > vm.currentPos);
            if (items.length === 0) return null;
            return items[0];
        }
    },
    methods: {
        getMilestoneIcon(m) {
            const vm = this;
            if (m === undefined) return '';
            var icon = vm.$icons.collection.find(x => x.id === m.icon);
            if (icon === undefined) return '';
            return icon.src;
        },
        formatLongDuration(time) {
            var seconds = parseInt(time / 1000);
            var hours = Math.floor(seconds / 3600);
            seconds = parseInt(seconds - (hours * 3600));
            var minutes = Math.floor(seconds / 60);
            seconds = seconds - (minutes * 60);
            var strHours = hours.toString();
            if (strHours.length === 1) strHours = "0" + strHours;
            var strMinutes = minutes.toString();
            if (strMinutes.length === 1) strMinutes = "0" + strMinutes;
            var strSeconds = seconds.toString();
            if (strSeconds.length === 1) strSeconds = "0" + strSeconds;
            return strHours + ":" + strMinutes + ":" + strSeconds;
        },        
    }
}
</script>
<style scoped>
</style>