<template>
    <modal-dialog v-model="visible"
        title="Nach Tracks suchen"
        size="xl"
        onlyClose>
        <b-tabs>
            <b-tab title="In Spotify suchen" title-link-class="text-white">
                    <b-row class="align-items-center m-0 mt-3">
                        <b-col>
                            <b-icon icon="search" class="float-left mt-2 mr-2"/>
                            <b-form-input v-model="text" class="float-left mr-3" style="width: 20rem;" debounce="1000"/>
                        </b-col>
                    </b-row>
                    <b-row v-if="searching">
                        <b-col class="text-center">
                            <b-spinner/>
                        </b-col>
                    </b-row>        
                    <b-row v-if="tracks !== null && tracks.length === 0">
                        <b-col>
                            <b-alert show variant="black">
                                <span><b-icon icon="info-circle"/> Die Suche lieferte keine Ergebnisse.</span>
                            </b-alert>
                        </b-col>
                    </b-row>        
                    <b-row v-if="tracks !== null && tracks.length > 0">
                        <b-col>
                            <b-row v-for="t in tracks" v-bind:key="t.id" class="align-items-center bg-dark m-0 p-2 border-white border border-left-0 border-top-0 border-right-0 border-bottom-1">
                                <b-col cols="*" class="mr-3">
                                    <b-button v-if="previewTrack !== t" class="bg-secondary" @click="playPreview(t)" :disabled="t.preview_url === null || t.preview_url === ''"><b-icon icon="play"/></b-button>
                                    <b-button v-if="previewTrack === t" class="bg-secondary" @click="stopPreview()"><b-icon icon="stop"/></b-button>
                                </b-col>
                                <b-col cols="*" class="text-center">
                                    <img :src="imgSrc(t)" class="border border-light" style="width: 3rem; height: 3rem;"/>
                                </b-col>                    
                                <b-col>
                                    <span>{{ t.name }} ({{ artists(t) }})</span>
                                </b-col>
                                <b-col cols="*">
                                    <b-button v-if="!isTrackOnPlaylist(t)" variant="primary" @click="addTrack(t)"><b-icon icon="plus"/> Hinzufügen</b-button>
                                    <span v-if="isTrackOnPlaylist(t)" class="text-light">(in Playlist vorhanden)</span>
                                </b-col>
                            </b-row>
                        </b-col>
                    </b-row>
                    <iframe v-if="previewTrack !== null" :src="previewTrack.preview_url" style="display: none;"></iframe>
            </b-tab>
            <b-tab title="In meinen Songs suchen" title-link-class="text-white">
                <div class="m-3">(noch nicht implementiert)</div>
            </b-tab>
        </b-tabs>      
    </modal-dialog>
</template>
<script>
import modalDialog from './modal-dialog.vue';
export default {
    name: 'search',
    components: {
        modalDialog
    },
    data() {
        return {
            text: null,
            bpmFrom: null,
            bpmTo: null,
            searching: false,
            seachAgain: false,
            tracks: null,
            previewTrack: null
        }
    },
    props: {
        value: null,
        playlistTracks: null
    },
    computed: {
        visible: {
            get() {
                const vm = this;
                return vm.value;
            },
            set(v) {
                const vm = this;
                vm.$emit('input', v);
            }
        }
    },
    watch: {
        text() {
            const vm = this;
            if (vm.text === null || vm.text === undefined) return;
            if (vm.text === '') return;
            if (vm.text.length < 3) return;
            if (!vm.searching) {
                vm.search();
            } else {
                vm.searchAgain = true;
            }
        },
        visible() {
            const vm = this;
            if (vm.visible === false) {
                vm.previewTrack = null;
            }
        }
    },
    methods: {
        async search() {
            const vm = this;
            vm.searching = true;
            vm.result = null;
            vm.searchAgain = false;
            try {
                var result = await vm.$spotify.search(vm.text);
                if (result.items !== undefined) vm.tracks = result.items;
                vm.searching = false;
            } catch (err) {
                vm.searching = false;
                vm.error = err;
            }
            vm.searching = false;
            if (vm.seachAgain === true) {
                vm.search();
            }
        },
        imgSrc(track) {
            if (track === null || track.album === null || track.album.images === null) return '';
            if (track.album.images.length === 0) return '';
            return track.album.images[track.album.images.length-1].url;            
        },
        artists(track) {
            var artists = '';
            track.artists.forEach(a => {
                if (artists !== '') artists += ', ';
                artists += a.name;
            });
            return artists;
        },        
        playPreview(track) {
            const vm = this;
            vm.previewTrack = track;
        },
        stopPreview() {
            const vm = this;
            vm.previewTrack = null;
        },
        addTrack(t) {
            const vm = this;
            vm.$emit('onAddTrack', t);
        },
        isTrackOnPlaylist(t) {
            const vm = this;
            var existing = vm.playlistTracks.find(x => x.track.id === t.id);
            return (existing !== undefined);
        }
    }
}
</script>
<style scoped>
</style>