import Vue from 'vue'
import Vuex from 'vuex'
import VueRouter from 'vue-router'
import App from './App.vue'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import moment from 'moment'
import SpotifyClient from './libs/spotify-web-api.js'
import CloudStorage from './libs/cloud-storage.js'
import icons from './milestone-icons.js';

import './app.scss'

Vue.config.productionTip = false

Vue.use(Vuex)
Vue.use(VueRouter)
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

// Register moment
moment.locale('de');
Vue.prototype.$moment = moment;

// Milestone icons
Vue.prototype.$icons = icons;

// Libraries
Vue.prototype.$spotify = new SpotifyClient();
Vue.prototype.$cloud = new CloudStorage();

// Register Vuex store
import { store } from './store.js';

// Vue router
var router = new VueRouter({
  mode: 'history',
  routes: []
});

new Vue({
  render: h => h(App),
  router,  
  store
}).$mount('#app')
