<template>
    <b-modal
        v-model="visible"
        dialog-class="border border-light"
        content-class="dialog"
        header-bg-variant="dark"
        footer-bg-variant="dark"        
        :title="title"  
        header-close-variant="white"
        :hide-footer="onlyClose"
        ok-variant="success"
        :ok-title="okTitle"
        cancel-variant="danger"
        cancel-title="Abbruch"
        scrollable
        static
        :size="size"
        @ok="ok">
        <slot/>
    </b-modal>    
</template>
<script>
export default {
    name: 'modal-dialog',
    data() {
        return {            
        }
    },
    props: {
        value: null,
        title: {
            type: String,
            default: 'Dialog'
        },
        onlyClose: {
            type: Boolean,
            default: false
        },
        okTitle: {
            type: String,
            default: 'Ok'
        },
        size: {
            type: String,
            default: 'md'
        }
    },
    computed: {
        visible: {
            get() {
                const vm = this;
                return vm.value;
            },
            set(v) {
                const vm = this;
                vm.$emit('input', v);
            }
        }
    },
    methods: {
        ok() {
            const vm = this;
            vm.$emit('ok');
        }
    }
}
</script>
<style scoped>
</style>