<template>
    <div class="h-100 overflow-hidden">
        <b-row v-if="editTrack !== null" class="h-100 overflow-auto">
            <b-col>
                <track-edit v-model="editTrack"
                    @onClose="onTrackClose"/>    
            </b-col>
        </b-row>        
        <b-row v-show="editTrack === null" class="h-100">
            <b-col cols="6" class="h-100">
                <contentbox :text="value.name" icon="music-note-list"
                    canAdd
                    addLabel="Tracks hinzufügen..."
                    canClose @onClose="onClose"
                    @onAdd="openSearch"
                    @onRefresh="loadTracks">
                    <template slot="buttons">
                        <b-button v-if="tracks.length > 0" variant="primary" :disabled="analyzing" @click="analyzeTracks"><b-icon icon="search"/> Audio-Daten analysieren...</b-button>
                    </template>
                    <b-container fluid class="h-100 w-100 overflow-scroll p-0 m-0">
                        <b-row>
                            <b-col>
                                <b-alert :show="error !== null" variant="danger" class="mb-2" dismissible>
                                    <b-icon icon="exclamation-triangle"/> {{ error }}
                                </b-alert>
                                <b-container v-if="analyzing" fluid class="p-3 text-center">
                                    <div class="mb-2">Track Metadaten in Playlist werden analysiert...</div>
                                    <b-progress :max="tracks.length" height="2rem">
                                    <b-progress-bar :value="currentAnalyzeTrackNo">
                                        <span>Track <strong>{{ currentAnalyzeTrackNo }} / {{ tracks.length }}</strong></span>
                                    </b-progress-bar>
                                    </b-progress>                                    
                                </b-container>
                                <b-container v-show="!analyzing" fluid class="p-0">
                                    <b-row v-if="tracksLoading">
                                        <b-col class="text-center">
                                            <span><b-spinner/> Songs werden geladen...</span>
                                        </b-col>
                                    </b-row>
                                    <item-track v-for="t in tracks" v-bind:key="t.id"
                                        :track="t.track"
                                        :allowRemove="true"
                                        @onPlay="onTrackPlay"
                                        @onEdit="onTrackEdit"
                                        @onRemove="onTrackRemove"/>
                                </b-container>
                            </b-col>
                        </b-row>
                    </b-container>
                </contentbox>
            </b-col>
            <b-col cols="6">
                <sessionInfo v-if="!searchOpen" :playlist-tracks="tracks"/>                          
            </b-col>
        </b-row>
        <search v-model="searchOpen"
            :playlistTracks="tracks"
            @onAddTrack="onAddTrack"/>         
    </div>
</template>
<script>
import contentbox from './contentbox.vue';
import itemTrack from './items/item-track.vue';
import trackEdit from './track-edit.vue';
import sessionInfo from './session-info.vue';
import search from './search.vue';
export default {
    name: 'playlist',
    components: {
        contentbox,
        itemTrack,
        trackEdit,
        sessionInfo,
        search
    },
    data() {             
        return {      
            error: null,
            tracks: [] ,
            tracksLoading: false,
            editTrack: null,
            searchOpen: false,
            analyzing: false,
            currentAnalyzeTrackNo: 0
        }
    },
    props: {
        value: null
    },
    computed: {
        spotify() {
            const vm = this;
            return vm.$spotify;
        },
        currentTrack() {
            const vm = this;
            return vm.$store.getters['getCurrentTrack'];
        }
    },
    mounted() {
        const vm = this;
        vm.loadTracks();             
    },
    methods: {
        loadTracks() {
            const vm = this;
            vm.tracks = [];
            vm.tracksLoading = true;
            vm.spotify.getTracksOfPlaylist(vm.value).then(data => {
                vm.tracksLoading = false;
                vm.tracks = data.items;                
            }).catch(err => vm.error = err);
        },
        onClose() {
            const vm = this;
            vm.tracksLoading = false;
            vm.$emit('onClose');
        },
        onTrackPlay(track) {
            const vm = this;
            var indexTrack = vm.tracks.find(x => x.track.id == track.id);
            if (indexTrack !== undefined) {
                var index = vm.tracks.indexOf(indexTrack);
                vm.spotify.play(vm.value.uri, index, vm.$store.state.spotifyDeviceId);
            }
        },        
        onTrackEdit(track) {
            const vm = this;
            vm.editTrack = track;
        },
        onTrackClose() {
            const vm = this;
            vm.editTrack = null;
        },
        onTrackRemove(track) {
            const vm = this;
            vm.$spotify.removeTrackFromPlaylist(vm.value, track).then(() => {
                vm.loadTracks();
            }).catch((err) => vm.error = err );
            vm.loadTracks();
        },
        openSearch() {
            const vm = this;
            vm.searchOpen = true;
        },
        onAddTrack(track) {
            const vm = this;
            var existing = vm.tracks.find(x => x.id === track.id);
            if (existing !== undefined) return;
            vm.$spotify.addTrackToPlaylist(vm.value, track).then(() => {
                vm.loadTracks();
            }).catch((err) => vm.error = err );
        },
        async analyzeTracks() {
            const vm = this;
            vm.currentAnalyzeTrackNo = 0;
            vm.analyzing = true;
            for (const t of vm.tracks) {
                vm.currentAnalyzeTrackNo++;  
                var trackData = await vm.getOrCreateTrackData(t.track);
                await vm.updateAudioFeatures(trackData)
            }
            vm.analyzing = false;
            vm.loadTracks();
        },
        async getOrCreateTrackData(track) {
            const vm = this;      
            try {
                var trackData = await vm.$cloud.getTrackData(track.id);
                if (trackData === null) {
                    return {
                        id: track.id,
                        milestones: []
                    }                    
                }
                return trackData;
            } catch(err) {
                vm.error = err;
            }     
        },
        async updateAudioFeatures(trackData) {
            const vm = this;        
            try {
                var data = await vm.$spotify.getAudioFeatures(trackData.id);
                trackData.bpm = data.tempo;
                trackData.time_signature = data.time_signature;
                trackData.accousticness = data.accousticness;
                trackData.danceability = data.danceability;
                trackData.energy = data.energy;
                trackData.instrumentalness = data.instrumentalness;
                trackData.key = data.key;
                trackData.loudness = data.loudness;
                trackData.speechiness = data.speechiness;
                trackData.valence = data.valence;
                await vm.$cloud.saveTrackData(trackData);
            } catch(err) {
                vm.error = err;
            }
        }
    }
}
</script>
<style scoped>
</style>