<template>
    <b-row class="text-center h-100 align-items-center">
        <b-container fluid="sm" class="col-12 col-md-4">
            <img src="../assets/img/logo-start.png" class="w-100"/><br/>            
            <b-button block variant="primary" @click="login" :disabled="processing" size="lg" class="pt-3 pb-3"><b-icon v-if="processing" icon="arrow-counterclockwise" animation="spin-reverse"/><b-icon v-if="!processing" icon="check"/> Mit Spotify anmelden</b-button>        
        </b-container>
    </b-row>
</template>
<script>
export default {
    name: 'login',
    data() {
        return {     
            processing: false       
        }
    },
    methods: {
        login() {
            const vm = this;
            vm.processing = true;
            var state = vm.$store.getters['newGuid'];
            localStorage['spotifyState'] = state;
            vm.$spotify.startAuth(state);
        }
    }
}
</script>
<style scoped>

</style>