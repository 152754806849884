<template>
  <div class="appContent">
    <div class="appHeader" v-if="spotifyConnected">
      <player ref="player"/>    
    </div>
    <b-alert v-if="error !== null" show variant="danger" dismissible class="position-fixed fixed-top m-0 rounded-0">
      <span><b-icon icon="exclamation-triangle"/> {{ error }}</span>
    </b-alert>      
    <login v-if="!spotifyConnected"/>      
    <div v-if="spotifyConnected" class="container-fluid body-content p-0 m-0">
      <home class="mt-0"/>      
    </div>
  </div>
</template>

<script>
import login from './components/login.vue';
import player from './components/player.vue';
import home from './components/home.vue';
export default {
  name: 'App',
  components: {
    login,
    player,
    home
  },
  data() {
    return {     
      error: null,
      spotifyConnected: false,
      refreshTokenCycle: null,
      webPlaybackSkdReady: false
    }
  },
  computed: {
    spotifyCode() {
      const vm = this;
      return vm.$store.state.spotifyCode;
    },
    currentTrack() {
      const vm = this;
      return vm.$store.state.currentTrack;
    }
  },
  created() {
    const vm = this;
    var query = vm.$route.query;
    if (query.state !== undefined) {
      if (query.state !== localStorage['spotifyState']) {
        vm.error = 'Spotify state nicht gültig.';
        vm.spotifyConnected = false;
        return;
      } else {
        if (query.error !== undefined) {
          vm.error = 'Fehler bei Spotify-Authentifizierung: ' + query.error;
          vm.spotifyConnected = false;
          return;
        }
        vm.spotifyConnected = false;
        vm.$store.state.spotifyCode = query.code;          
        vm.$spotify.getToken(query.code).then((token) => {
          vm.$store.state.spotifyToken = token;
          vm.$spotify.getUserProfile().then(async (profile) => {
            vm.$store.state.spotifyProfile = profile;
            await vm.$cloud.init(profile.id);
            vm.spotifyConnected = true;  
            vm.startRefreshTokenCycle();            
            vm.initPlayer();                
          }).catch((err) => { vm.error = err; });
        }).catch((err) => {          
          vm.error = err;
        });
      }    
    }
  },
  mounted() {
    const vm = this;
    window.onSpotifyWebPlaybackSDKReady = () => {
      vm.webPlaybackSkdReady = true;
    };    
  },
  methods: {
    startRefreshTokenCycle() {
      const vm = this;
      var currentToken = vm.$store.state.spotifyToken;
      if (currentToken === null) return;
     // Get new token 3 Minutes before access token expires      
      var seconds = parseInt((currentToken.expires_in - 180) * 1000);
      vm.refreshTokenCycle = setInterval(() => {
        currentToken = vm.$store.state.spotifyToken;
        if (currentToken !== null) {
          vm.$spotify.refreshToken(currentToken.refresh_token).then((newToken) => {
            vm.$store.state.spotifyToken = newToken;
          }).catch((err) => {
            vm.error = "Error on refreshing spotify access token: " + err;
          })
        }
      }, seconds);
    },
    async initPlayer() {
      const vm = this;
      var waitForSdk = setInterval(() => {
        const token = vm.$store.state.spotifyToken;
        if (token !== null) {
          clearInterval(waitForSdk);
          var webPlayer = new window.Spotify.Player({
            name: 'Ollis Spinning Companion App',
            getOAuthToken: cb => { cb(token.access_token); }
          });    
          vm.$refs.player.init(webPlayer);
        }
      }, 500);
    }
  }
}
</script>

<style>
</style>
