import axios from 'axios';
const APP_URL = process.env.VUE_APP_URL;
export default class SpotifyClient {

    appUrl = APP_URL;
    clientId = 'b86cc28b95b74d4f98f1fff71b263755';
    clientSecret = '767a992617f1478181a52a0caec0126b';

    scopes = [
        'user-read-private',
        'user-read-email',
        'playlist-modify-public',
        'playlist-modify-private',
        'streaming',
        'user-modify-playback-state',
        'user-read-playback-state',
        'user-read-currently-playing',
        'app-remote-control'
    ]

    spotifyToken = null;
    axiosConfig = null;

    constructor() {
    }

    getAxiosConfig = function(forceBasicAuth = false, data = null) {
        var base64Auth = btoa(this.clientId + ':' + this.clientSecret);
        var headers = {};
        if (this.spotifyToken !== null && forceBasicAuth === false) {
            headers = {
                'Authorization': 'Bearer ' + this.spotifyToken.access_token,                    
                'Content-Type': 'application/json;charset=UTF-8'
            };
        } else {
            headers = {
                'Authorization': 'Basic ' + base64Auth,
                'Content-Type': 'application/x-www-form-urlencoded'
            };
        }
        if (data === null) {
            return {
                withCredentials: false,
                headers: headers
            };
        } else {
            return {
                withCredentials: false,
                headers: headers,
                data: data
            };            
        }
    }

    startAuth = function(state) {
        var redirectUri = encodeURI(this.appUrl);
        var scopesPar = this.scopes.join('%20');
        var authUri = 'https://accounts.spotify.com/authorize?client_id=' + this.clientId + '&response_type=code&state=' + state + '&redirect_uri=' + redirectUri + '&scope=' + scopesPar;
        window.location.href = authUri;
    }

    getToken = function(code) {
        const that = this;
        return new Promise((resolve, reject) => {
            var params = new URLSearchParams();
            params.append('grant_type', 'authorization_code');
            params.append('code', code);
            params.append('redirect_uri', this.appUrl);
            axios.post('https://accounts.spotify.com/api/token', params, that.getAxiosConfig()).then((response) => {
                that.spotifyToken = response.data;
                resolve(response.data);
            }).catch((err) => {
                if (err.response.data.error !== undefined && err.response.data.error === 'invalid_grant') {
                    that.startAuth(localStorage['spotifyState']);
                    return;
                }
                reject(that.getErrorText('getToken', err));
            });
        });
    }

    refreshToken = function(refresh_token) {
        const that = this;
        return new Promise((resolve, reject) => {
            var params = new URLSearchParams();
            params.append('grant_type', 'refresh_token');
            params.append('refresh_token', refresh_token);
            axios.post('https://accounts.spotify.com/api/token', params, that.getAxiosConfig(true)).then((response) => {
                that.spotifyToken = response.data;
                resolve(response.data);
            }).catch((err) => {
                reject(that.getErrorText('refreshToken', err));
            });
        });
    }

    getUserProfile = function() {
        const that = this;
        return new Promise((resolve, reject) => {
            axios.get('https://api.spotify.com/v1/me', that.getAxiosConfig()).then((response) => {
                resolve(response.data);
            }).catch((err) => {
                reject(that.getErrorText('getToken', err));
            });
        });
    }

    getPlaylists = function(userProfile, nextUrl = null, items = null) {
        const that = this;
        return new Promise((resolve, reject) => {
            if (items === null) items = [];
            var href = 'https://api.spotify.com/v1/users/' + userProfile.id + '/playlists?offset=0'; 
            if (nextUrl !== null) href = nextUrl;
            axios.get(href, that.getAxiosConfig()).then((response) => {
                items = items.concat(response.data.items);
                nextUrl = response.data.next;
                if (nextUrl !== null) {
                    that.getPlaylists(userProfile, nextUrl, items).then((allItems) => {
                        resolve(allItems);
                    }).catch((err) => {
                        throw err;
                    })
                } else {
                    resolve(items);
                }
            }).catch((err) => {
                reject(that.getErrorText('getPlaylists', err));
            });
        });
    }

    getTracksOfPlaylist = function(playlist) {
        const that = this;
        return new Promise((resolve, reject) => {
            axios.get('https://api.spotify.com/v1/playlists/' + playlist.id + '/tracks', that.getAxiosConfig()).then((response) => {
                resolve(response.data);
            }).catch((err) => {
                reject(that.getErrorText('getTracksOfPlaylist', err));
            });
        });
    }

    addTrackToPlaylist = function(playlist, track) {
        const that = this;
        var data = {
            uris: [ track.uri ]
        };
        return new Promise((resolve, reject) => {
            axios.post('https://api.spotify.com/v1/playlists/' + playlist.id + '/tracks', data, that.getAxiosConfig()).then((response) => {
                console.log(response);
                resolve(true);
            }).catch((err) => {
                reject(that.getErrorText('addTrackToPlaylist', err));
            });
        });
    }

    removeTrackFromPlaylist = function(playlist, track) {
        const that = this;
        return new Promise((resolve, reject) => {
            var data = {
                tracks: [
                    { uri: track.uri }
                ]                
            };
            axios.delete('https://api.spotify.com/v1/playlists/' + playlist.id + '/tracks', that.getAxiosConfig(false, data)).then((response) => {
                console.log(response);
                resolve(true);
            }).catch((err) => {
                reject(that.getErrorText('removeTrackFromPlaylist', err));
            });
        });        
    }

    getAudioFeatures = function(trackId) {
        const that = this;
        return new Promise((resolve, reject) => {
            axios.get('https://api.spotify.com/v1/audio-features/' + trackId, that.getAxiosConfig()).then(response => {
                resolve(response.data);
            }).catch(err => {
                reject(that.getErrorText('getAudioFeatures', err));
            });
        });
    }

    getAudioAnalysis = function(trackId) {
        const that = this;
        return new Promise((resolve, reject) => {
            axios.get('https://api.spotify.com/v1/audio-analysis/' + trackId, that.getAxiosConfig()).then(response => {
                resolve(response.data);
            }).catch(err => {
                reject(that.getErrorText('getAudioAnalysis', err));
            });
        });
    }

    play = function(playlistUri = null, trackNumber = null, deviceId = null) {
        const that = this;
        return new Promise((resolve, reject) => {
            var href = 'https://api.spotify.com/v1/me/player/play';
            if (deviceId !== null)  href += "?device_id=" + deviceId;
            var data = {};
            if (playlistUri !== null) {
                data['context_uri'] = playlistUri;
            }
            if (trackNumber !== null) {
                data['offset'] = {
                    position: trackNumber
                };
            }            
            axios.put(href, data, that.getAxiosConfig()).then(() => {
                resolve(true);
            }).catch(err => {
                reject(that.getErrorText('play', err));
            });
        });
    }

    playTrack = function(uri, deviceId = null) {
        const that = this;
        return new Promise((resolve, reject) => {
            var href = 'https://api.spotify.com/v1/me/player/play';
            if (deviceId !== null)  href += "?device_id=" + deviceId;
            var data = {
                uris: [ uri ]
            };          
            axios.put(href, data, that.getAxiosConfig()).then(() => {
                resolve(true);
            }).catch(err => {
                reject(that.getErrorText('playTracks', err));
            });
        });
    }

    pause = function(deviceId) {
        const that = this;
        return new Promise((resolve, reject) => {
            var href = 'https://api.spotify.com/v1/me/player/pause?device_id=' + deviceId;
            axios.put(href, null, that.getAxiosConfig()).then(() => {
                resolve(true);
            }).catch(err => {
                reject(that.getErrorText('pause', err));
            });
        });
    }

    seek = function(deviceId, position) {
        const that = this;
        return new Promise((resolve, reject) => {
            var href = 'https://api.spotify.com/v1/me/player/seek?device_id=' + deviceId + '&position_ms=' + position;
            axios.put(href, null, that.getAxiosConfig()).then(() => {
                resolve(true);
            }).catch(err => {
                reject(that.getErrorText('seek', err));
            });
        });
    }

    previous = function(deviceId) {
        const that = this;
        return new Promise((resolve, reject) => {
            var href = 'https://api.spotify.com/v1/me/player/previous?device_id=' + deviceId;
            axios.post(href, null, that.getAxiosConfig()).then(() => {
                resolve(true);
            }).catch(err => {
                reject(that.getErrorText('pause', err));
            });
        });
    }

    next = function(deviceId) {
        const that = this;
        return new Promise((resolve, reject) => {
            var href = 'https://api.spotify.com/v1/me/player/next?device_id=' + deviceId;
            axios.post(href, null, that.getAxiosConfig()).then(() => {
                resolve(true);
            }).catch(err => {
                reject(that.getErrorText('pause', err));
            });
        });
    }

    setVolume = function(deviceId, volumePercent) {
        const that = this;
        return new Promise((resolve, reject) => {
            var href = 'https://api.spotify.com/v1/me/player/volume?device_id=' + deviceId + '&volume_percent=' + volumePercent;
            axios.put(href, null, that.getAxiosConfig()).then(() => {
                resolve(true);
            }).catch(err => {
                reject(that.getErrorText('setVolume', err));
            });
        });
    }

    getPlaybackInfo = function() {
        const that = this;
        return new Promise((resolve, reject) => {
            axios.get('https://api.spotify.com/v1/me/player', that.getAxiosConfig()).then(response => {
                resolve(response.data);
            }).catch(err => {
                reject(that.getErrorText('getPlaybackInfo', err));                
            });
        });
    }

    search = function(searchText) {
        const that = this;
        return new Promise((resolve, reject) => {
            var query = searchText;
            query = encodeURI(query);
            axios.get('https://api.spotify.com/v1/search?q=' + query + '&type=track', that.getAxiosConfig()).then(response => {
                resolve(response.data.tracks);
            }).catch(err => {
                reject(that.getErrorText('getAudioFeatures', err));
            });
        });
    }

    getErrorText = function(req, err) {
        var errText = 'Spotify error ' + err.response.status + ' on request \'' + req + '\': ';
        if (err.response.data !== undefined) {
            if (err.response.data.error_description !== undefined) {
                return errText += ' ' + err.response.data.error_description + ' (' + err.response.data.error + ')';            
            } else {
                if (err.response.data.error.message !== undefined) {
                    return errText += ' ' + err.response.data.error.message;
                } else {
                    return errText += ' ' + err.response.data.error;
                }
            }
        }
        return errText;
    }

}